
.container {
  text-align: center;
  width: 100%;
  color: white;
}

.banana-text {
  color: yellow;
}

.loading-bar {
  width: 50%; /* adjust width as needed */
  height: 20px; /* adjust height as needed */
  background-color: #333;
  border-radius: 10px; /* half of height */
  overflow: hidden;
  margin: 20px auto; /* adjust margin as needed */
}

.progress {
  width: 10%; /* adjust initial progress */
  height: 100%;
  background-color: yellow; /* adjust color as needed */
  border-radius: 10px; /* half of height */
  border-color: black;
  transition: width 1s ease; /* adjust animation duration as needed */
}

.trailer {
  /* max-width: 100%;
  --preferredWidth: 1080px;
  width: 100vw;
  height: calc(100vh / (16/9)); */
  width: 960px;
  height: 540px;
  object-fit: cover;
  max-width: fill-available;
}

.video-container {
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, 9 / 16) * 50%);
  height: 0;
  width: 50%;
  max-width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}